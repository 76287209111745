export default {
  install(app) {
    this.created();
    app.config.globalProperties.$t = this;
  },
  data: {},
  created: function () {},
  get: function (txt) {
    return txt;
  },
};
