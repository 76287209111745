<template>
  <div class="container tasklist">
    <div class="row new-task-form">
      <div class="col-sm-10 offset-sm-1">
        <div class="form-group-buttons">
          <button
            class="btn btn-checkbox btn-sm space-button"
            :class="{ active: showCompleted, faded: !showCompleted }"
            title="Show completed"
            @click="toggleCompleted()"
          >
            <i
              class="fa"
              :class="{
                'fa-check-circle': showCompleted,
                'fa-times-circle': !showCompleted,
              }"
              style="margin-right: 5px"
            />
            <span class="d-none d-sm-inline">{{
              $t.get('Show completed')
            }}</span>
            <span class="d-sm-none small">{{ $t.get('Completed') }}</span>
          </button>
          <button
            class="btn btn-checkbox btn-sm space-button"
            :class="{ active: showDue, faded: !showDue }"
            title="Show due list"
            @click="toggleDue()"
          >
            <i
              class="fa"
              :class="{
                'fa-check-circle': showDue,
                'fa-times-circle': !showDue,
              }"
              style="margin-right: 5px"
            />
            <span class="d-none d-sm-inline">{{ $t.get('Show due') }}</span>
            <span class="d-sm-none small">{{ $t.get('Due') }}</span>
          </button>
          <button
            class="btn btn-primary btn-sm space-button"
            @click="updateTasks()"
          >
            <i class="fa fa-refresh" />
          </button>
          <button
            class="btn btn-primary btn-sm space-button"
            @click="createTaskList()"
          >
            <i class="fa fa-plus" />
          </button>
        </div>

        <div class="form-group text-right">
          <input
            id="new-task"
            v-model="newTaskText"
            type="text"
            class="form-control"
            placeholder="I want to ..."
            @keyup="tagify"
            @keyup.enter="createTask"
          /><br />
          <label for="new-task-list" class="small">
            {{ $t.get('file under') }}
          </label>
          <select id="new-task-list" v-model="newTaskListId" class="small">
            <option
              v-for="(list, index) in lists"
              :key="'list-option' + index"
              :value="list.id"
            >
              {{ list.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="list-row row">
      <TaskList
        v-if="showDue && dueList.tasks.length > 0"
        :tasks="dueList.tasks"
        name="Due"
      />
      <template v-for="(list, index) in filteredLists" :key="'list-' + index">
        <TaskList
          v-if="!showListId || list.id === showListId"
          :id="list.id"
          :tasks="list.tasks"
          :name="list.name"
          :lists="lists"
          :selected-task-list-id="showListId"
          :edit-task-list="editTaskList"
          :delete-task-list="deleteTaskList"
        />
      </template>
    </div>
  </div>
</template>

<script>
import TaskList from '@/components/TaskList.vue';
import DateShortcutParser from '@/services/DateShortcutParser';
import moment from 'moment';

export default {
  components: { TaskList, TaskList },
  data: function () {
    return {
      newTaskText: '',
      newTaskListId: -1,
      selectedTaskListId: null,
      lists: [],
      draggedTask: null,
      showCompleted: false,
      showDue: false,
      dueList: {
        hidden: false,
        lastUpdate: null,
        tasks: [],
        timer: null,
      },
      showListId: null,
    };
  },
  computed: {
    filteredLists() {
      const uncategorisedList = this.lists.find((list) => list.id === null);
      if (uncategorisedList && uncategorisedList.tasks.length === 0) {
        return this.lists.filter((list) => list.id !== null);
      }
      return this.lists;
    },
  },
  mounted: function () {
    if (
      window.electronAPI &&
      typeof window.electronAPI.setWindowTitle === 'function'
    ) {
      window.electronAPI.setWindowTitle('ChronoTASK: ' + this.$t.get('Tasks'));
    }
    this.emitter.on('tasks-page:update-tasks', () => {
      this.updateTasks();
    });
    this.emitter.on('tasks-page:update-tasklists', () => {
      this.updateLists();
    });
    this.emitter.on('tasks:move-task', (taskId, listId) => {
      this.moveTask(taskId, listId);
    });
    this.updateLists();
  },
  updated: function () {
    if (this.$route.params?.id) {
      this.showListId = parseInt(this.$route.params.id);
      this.emitter.emit('nav-bar:showBack');
    } else {
      this.showListId = null;
      this.emitter.emit('nav-bar:hideBack');
    }
    // this.updateLists();
  },
  methods: {
    toggleCompleted() {
      this.showCompleted = !this.showCompleted;
      this.updateTasks();
    },
    toggleDue() {
      this.showDue = !this.showDue;
    },

    tagify(event) {
      if (event.target.selectionStart || event.target.selectionStart === '0') {
        const pos = parseInt(event.target.selectionStart, 10);
        const val = event.target.value;

        // Get the current word - does it start with a hash?
        let p = pos - 1;
        while (p > 0 && val[p] !== ' ') {
          p -= 1;
        }

        if (p < 0) {
          return;
        }

        const currentWord = val.substring(p, pos).trim();
        if (currentWord.length > 0 && currentWord[0] === '#') {
          console.log('Got a tag: ' + currentWord.substring(1));

          // TODO: Show suggestions
        }
      }
    },

    findList(id) {
      for (const list of this.lists) {
        if (list.id === id) {
          return list;
        }
      }
      return null;
    },

    addTaskToLists(task, index = null) {
      const list = this.findList(
        task.tasklist === null ? null : task.tasklist.id
      );

      if (list !== null) {
        if (index !== null) {
          list.tasks.splice(index, 0, task);
        } else {
          list.tasks.push(task);
        }
      }
    },

    createTask() {
      if (this.newTaskText.length === 0) {
        return;
      }

      const self = this;
      const newTask = {
        text: this.newTaskText,
        due: null,
      };

      const newTaskText = this.newTaskText;

      const dsp = new DateShortcutParser();
      dsp.parseDateShortcuts(newTask);

      this.newTaskText = '';

      this.$api
        .post('/tasks', {
          text: newTask.text,
          due: newTask.due,
          list: this.newTaskListId,
          index: 0,
        })
        .then((res) => {
          self.addTaskToLists(res.data, res.data.index);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    moveTask(taskId, listId) {
      this.$api
        .post(`/tasks/${taskId}/list/`, {
          index: 0,
          list: listId,
        })
        .then((res) => {
          this.updateTasks();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    updateTasks() {
      const self = this;

      for (const list of this.lists) {
        list.tasks = [];
      }

      this.$api
        .get('/tasks', {
          completed: this.showCompleted,
        })
        .then((res) => {
          for (const task of res.data) {
            self.addTaskToLists(task);
          }
          self.updateDueTasks();
        })
        .catch((err) => {
          console.error('Error fetching tasks:', err);
        });
    },
    updateDueTasks() {
      const now = moment();
      now.add(1, 'd');

      const tasks = [];
      for (const list of this.lists) {
        for (const task of list.tasks) {
          if (task.due && !task.completed) {
            const td = moment(task.due);
            if (td <= now) {
              task.due = td;
              tasks.push(task);
            }
          }
        }
      }

      this.dueList.tasks = tasks.sort((a, b) => {
        return a.due > b.due ? 1 : 0;
      });
    },
    updateLists() {
      const self = this;
      this.$api
        .get('/tasklists')
        .then((res) => {
          self.lists = res.data;
          self.lists.push({
            id: null,
            name: 'Uncategorised',
          });
          if (self.newTaskListId === -1) {
            self.newTaskListId = self.lists[0].id;
          }
          self.updateTasks();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async createTaskList() {
      let listName = '';
      if (window.electronAPI) {
        try {
          listName = await window.electronAPI.showPrompt(
            'New List Name',
            `New List ${this.lists.length}`
          );
        } catch (error) {
          return;
        }
      } else {
        listName = prompt('New list name', `New List ${this.lists.length}`);
      }

      if (!listName || listName.trim() === '') return;

      this.$api
        .post('/tasklists', { name: listName })
        .then(() => {
          console.log('✔ Task list created successfully');
          this.updateLists();
        })
        .catch(console.error);
    },

    async editTaskList(listId) {
      const listIndex = this.lists.findIndex((list) => list.id === listId);
      const list = this.lists[listIndex];
      let listName = '';

      if (window.electronAPI) {
        try {
          listName = await window.electronAPI.showPrompt(
            'Edit List Name',
            list.name
          );
        } catch (error) {
          return;
        }
      } else {
        listName = prompt('Edit list name', list.name);
      }

      if (!listName || listName.trim() === '') return;

      this.$api
        .put(`/tasklists/${listId}`, {
          name: listName,
        })
        .then(() => {
          list.name = listName;
        })
        .catch(console.error);
    },

    deleteTaskList(listId) {
      if (
        !confirm(
          'Are you SURE you want to delete this list?  Any tasks in it will be uncategorised.'
        )
      ) {
        return;
      }

      const listIndex = this.lists.findIndex((list) => list.id === listId);
      const list = this.lists[listIndex];

      this.$api
        .delete(`/tasklists/${list.id}`)
        .then((res) => {
          this.updateLists();
        })
        .catch((err) => {
          console.error();
        });
    },
  },
};
</script>

<style lang="scss">
.new-task-form {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}
.new-task-form #new-task {
  display: inline-block;
  // width: calc(100% - 64px);
  width: 100%;
}
.new-task-form .small {
  font-size: 10px;
}
.list-row {
  margin-left: 0px;
  margin-right: 0px;
}
.list h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px;
  margin-bottom: 6px;
  position: relative;
}
.list h2 .list-buttons {
  position: absolute;
  font-size: 13px;
}
.list-row .header {
  cursor: pointer;
  font-size: 1.4rem;
}
.list-row .header-icon {
  opacity: 0.5;
  font-size: 18px;
}

.list-row {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 171px;
  overflow-y: auto;
  height: calc(100% - 171px);
  @media (min-width: $breakpoint-lg) {
    height: calc(100% - 160px);
  }
}
.electron-app .list-row {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 171px;
  overflow-y: auto;
  height: calc(100% - 171px);
  @media (min-width: $breakpoint-lg) {
    height: calc(100% - 160px);
  }
}
.btn-sm {
  margin-right: 5px;
}

.form-group-buttons {
  margin-bottom: 1rem;
}
.task .task-body .side-buttons,
.list-buttons {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 8px;
  z-index: 10;
}
.tag {
  font-weight: bold;
  color: $col-button;
}

@media (min-width: $breakpoint-lg) {
  .list-row {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 160px;
  }
}
</style>
