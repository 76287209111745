import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/js/bootstrap.js';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';

import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toasted from 'vue-toasted';
import mitt from 'mitt';

import ApiService from './services/ApiService.js';
import I18n from './services/I18n.js';

import { createRouter } from '@/router';
import App from './App.vue';

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

// Toasted configuration -- Start
const prevPrototype = app.prototype;

app.prototype = {};
app.use(Toasted, {
  duration: 5000,
  position: 'top-right',
});

Object.assign(app.config.globalProperties, app.prototype);
app.prototype = prevPrototype;
// Toasted configuration -- End

app.use(VueAxios, axios).use(ApiService);
app.use(I18n);

const router = createRouter(app);
app.use(router);

app.mount('#app');

window.isElectron = 'electronAPI' in window ? true : false;

if (window.isElectron) {
  window.electronAPI.onNavigate((_event, route, params = {}) => {
    if (router.appInitialized) {
      router.push({ path: route, query: params });
    } else {
      router.initialRoute = { path: route, query: params };
    }
  });
}
