<template>
  <div v-if="user" class="container-fluid">
    <div class="row topbar">
      <div class="col-2 col-sm-1">
        <img src="@/assets/icon.png" alt="ChronoTask" class="iconlogo" />
      </div>
      <!-- <div class="col-2 col-sm-1 buttons">
        <a v-if="showBack" class="btn btn-primary" @click="back()"
          ><i class="fa fa-arrow-left"
        /></a>
      </div> -->
      <div class="col-10 col-sm-11">
        <ul class="topbar-tabs">
          <router-link
            v-for="(tab, index) in tabs"
            :key="index"
            v-slot="{ href, route, navigate, isActive, isExactActive }"
            :to="{ name: tab }"
            custom
          >
            <li
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              @click="navigate"
            >
              <a :href="href" :title="route.name" @click="navigate">
                <i :class="'fa ' + icons[index]" />
                <span class="tab-label">{{ $t.get(route.name) }}</span>
              </a>
            </li>
          </router-link>
          <!-- <li routerLink='/account' [ngClass]="{'active': tab === 'account'}"><app-avatar [user]="authService.currentUser"></app-avatar></li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      tabs: [],
      icons: [],
      showBack: false,
      currentTab: null,
    };
  },
  mounted() {
    this.emitter.on('nav-bar:showBack', () => {
      this.showBack = true;
    });
    this.emitter.on('nav-bar:hideBack', () => {
      this.showBack = false;
    });

    this.tabs = this.isTimerEnabled()
      ? ['Timer', 'Tasks', 'Notes', 'Account']
      : ['Tasks', 'Notes', 'Account'];
    this.icons = this.isTimerEnabled()
      ? ['fa-clock', 'fa-tasks', 'fa-sticky-note', 'fa-user']
      : ['fa-tasks', 'fa-sticky-note', 'fa-user'];
  },
  methods: {
    back: function () {
      this.$router.back();
    },
    isTimerEnabled() {
      return process.env.VUE_APP_ENABLE_TIMER === 'true';
    },
  },
};
</script>

<style lang="scss">
.topbar {
  background: $col-navbar-background;
  border-bottom: 1px solid $col-border;
  padding: 0px;
  height: $topbar-height;
  box-sizing: border-box;
}
.topbar .topbar-tabs {
  padding: 0px;
  margin: 0px;
  list-style: none;
  height: $topbar-height;

  display: flex;
  align-items: flex-end;
  justify-content: right;
}
.topbar .topbar-tabs li {
  display: inline-block;
  margin: 0px;
}
.topbar .topbar-tabs li {
  border: 1px solid #aaa;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  padding: 2px 8px;
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $col-button;
}

.topbar .topbar-tabs li a {
  text-decoration: none;
  font-size: 0.8rem;
  color: $col-white;
  font-weight: 400;
}
.topbar .topbar-tabs li a > .tab-label {
  display: none;
  margin-left: 4px;
}
.topbar .topbar-tabs li:hover .tab-label {
  display: inline;
}

.topbar .topbar-tabs li:hover,
.topbar .topbar-tabs li:active {
  background: $col-button-hover;
  color: unset;
  // transition: all 0.5s;
}
.topbar .topbar-tabs li.router-link-active {
  background: $col-white;

  a {
    color: unset;
  }
}

.topbar .buttons {
  display: flex;
  align-items: center;
}

.topbar .iconlogo {
  height: $topbar-height;
}

// Root
// html, body, app-root, ng-component {
//   min-width: 100%; min-height: 100%;
//   width: 100%; height: 100%;
// }

// Avatar
.user-avatar {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 24px;
  background: $col-avatar-background;
  color: $col-button;
}

.topbar .user-avatar {
  width: 26px;
  height: 26px;
  float: left;
  font-size: 12px;
  position: relative;
}
.user-avatar .user-initial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-weight: bold;
  color: $col-white;
}

@media screen and (min-width: $breakpoint-xs) {
  .topbar .topbar-tabs li a > .tab-label {
    display: inline;
  }
}

// For desktop displays (or big tablets)
@media screen and (min-width: $breakpoint-md) {
  .topbar,
  .topbar .topbar-tabs,
  .topbar .iconlogo {
    height: $topbar-height-desktop;
  }
  .topbar .topbar-tabs li a {
    font-size: 1rem;
  }
  .topbar .topbar-tabs li {
    padding: 4px 16px;
  }
}
</style>
