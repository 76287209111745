<template>
  <div
    class="d-flex justify-content-center align-items-center fullscreen flex-column"
  >
    <img src="@/assets/logo_square.png" alt="ChronoTask" class="loginlogo" />

    <div class="form standalone loginform">
      <h1>Login</h1>

      <form @submit.prevent="login()">
        <div class="form-group mb-3">
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control"
            placeholder="Email"
            :disabled="processing"
          />
        </div>
        <div class="form-group mb-3">
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Password"
            :disabled="processing"
          />
        </div>
        <StatusMessage ref="statusMessage" />
        <div class="text-center">
          <button type="submit" class="btn btn-primary" :disabled="processing">
            <i
              class="fas"
              :class="{
                'fa-sign-in-alt': !processing,
                'fa-spin fa-spinner': processing,
              }"
            />
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StatusMessage from '@/components/StatusMessage.vue';

export default {
  components: {
    StatusMessage,
  },
  data: function () {
    return {
      processing: false,
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      if (this.processing || !this.email || !this.password) {
        return;
      }

      this.processing = true;
      this.$refs.statusMessage.clear();

      if (!this.$api.apiUrl) {
        this.$refs.statusMessage.set('danger', 'Failed to discover server!');
        return;
      }

      this.axios
        .post(this.$api.apiUrl + '/auth/', {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.processing = false;
          if (res.data.success) {
            this.emitter.emit('authenticated', {
              token: {
                token: res.data.token,
              },
              user: res.data.pk,
            });
          } else {
            this.$refs.statusMessage.set('danger', res.data.message);
          }
        })
        .catch((err) => {
          this.processing = false;

          window.console.error(err);

          if (
            err.response !== undefined &&
            err.response.data !== undefined &&
            err.response.data.message !== undefined
          ) {
            this.$refs.statusMessage.set('danger', err.response.data.message);
          } else {
            this.$refs.statusMessage.set(
              'danger',
              'An error occurred whilst processing this action.  Please check your connection and try again.'
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginform {
  width: 90%;
  max-width: 450px;
}
.form {
  @include gradient-form;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 8px 0px;
  padding: 8px;
}
.form h1 {
  padding: 4px 0px 8px;
  margin: 0px 8px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.btn {
  padding: 9px 12px 8px;
  margin: 0px 8px;
}
.loginlogo {
  max-width: 40vw;
  max-height: 200px;
}
@media screen and (min-width: $breakpoint-md) {
  .loginlogo {
    max-width: 20vw;
  }
}
</style>
