<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="combined-component">
          <i class="fa fa-search" />
          <input v-model="searchField" class="input" @change="updateNotes()" />
        </div>
        <ul class="notelist">
          <router-link
            v-for="(note, index) in notes"
            :key="index"
            :to="'/notes/' + note.id"
            custom
          >
            <NoteItem :note="note" @click="openNote(note.id, note.title)" />
          </router-link>
        </ul>
      </div>
      <div class="note-editor-container d-none d-md-block col-md-6 col-lg-8">
        <router-view
          v-if="selectedNote"
          :key="$route.path"
          :selected-note="selectedNote"
        />
      </div>
    </div>
    <div class="fixed bottom-right">
      <button id="btn-add-note" class="btn-big-circle" @click="createNote">
        <i class="fa fa-plus" />
      </button>
    </div>
  </div>
</template>

<script>
import NoteItem from '@/components/NoteItem.vue';
import NoteEditor from '@/components/NoteEditor.vue';

export default {
  components: { NoteItem },
  data: function () {
    return {
      notes: [],
      searchField: '',
      selectedNote: null,
    };
  },
  mounted: function () {
    if (
      window.electronAPI &&
      typeof window.electronAPI.setWindowTitle === 'function'
    ) {
      window.electronAPI.setWindowTitle('ChronoTASK: ' + this.$t.get('Notes'));
    }
    this.emitter.on('notes-page:update-notes', () => {
      this.updateNotes();
    });
    this.updateNotes();
    if (this.$route.params.id) {
      this.selectNote(this.$route.params.id);
    }
  },
  methods: {
    updateNotes: function () {
      return this.$api
        .get('/notes', {
          q: this.searchField,
        })
        .then((res) => {
          this.notes = res.data;
          if (this.$route.params.id) {
            this.selectNote(this.$route.params.id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    createNote: function () {
      this.$api
        .post('/notes')
        .then((res) => {
          // Pass a callback to open the note once the notes list is updated
          this.updateNotes().then(() => {
            this.openNote(res.data.id, res.data.title);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    selectNote: function (id) {
      this.selectedNote = this.notes.find((note, index) =>
        note.id == id ? note : null
      );
    },
    getNotePath: function (id) {
      return '/notes/edit/' + id;
    },
    isCombinedView() {
      return window.innerWidth > 768;
    },
    openNote: function (id, title) {
      if (!this.isCombinedView()) {
        this.$root.openPopup(this.getNotePath(id), title);
        return;
      }
      this.$router.push(`/notes/${id}`);
      this.selectNote(id);
    },
  },
};
</script>

<style scoped>
.notelist {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.notelist a {
  text-decoration: none;
  font-weight: normal;
  color: unset;
}

.combined-component {
  display: flex;
  align-items: center;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 4px;
}
.combined-component i {
  margin: 0px 4px;
}
.combined-component input {
  border: 0px;
  width: 100%;
  margin: 0px 4px;
}

.note-editor-container {
  position: relative;
}
</style>
