/* eslint-disable vue/no-v-html */

<template>
  <div
    v-if="message != null"
    class="alert"
    :class="'alert-' + message.type"
    @click.prevent="onClick()"
    v-html="message.message"
  />
</template>

<script>
export default {
  name: 'StatusMessage',
  data: function () {
    return {
      message: null,
      callback: null,
    };
  },
  methods: {
    clear: function () {
      this.message = null;
    },
    set: function (type, message, callback) {
      this.message = {
        type,
        message,
      };
      this.callback = callback ? callback : null;
    },
    onClick: function () {
      if (this.callback) {
        this.callback();
      }
    },
  },
};
</script>

<style scoped>
.alert {
  cursor: pointer;
}
</style>
