<template>
  <pre><span v-for="(part, index) in formattedText" :key="'fpart-'+index"><span v-if="part.type === 'span'" :class="part.class">{{ part.text }}</span><a v-if="part.type === 'a'" :class="part.class" href="javascript:;" @click.stop.prevent="openLink(part.href)">{{ part.text }}</a> </span></pre>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  computed: {
    formattedText() {
      const parts = this.text.split(' ');
      const formattedParts = [];
      for (let i = 0; i < parts.length; i++) {
        const formattedPart = {
          text: parts[i],
          type: 'span',
          class: '',
        };

        if (parts[i].length > 0 && parts[i][0] === '#') {
          formattedPart['class'] = 'tag';
        } else if (parts[i].match(/^https?:/i)) {
          formattedPart['type'] = 'a';
          formattedPart['href'] = parts[i];
          formattedPart['class'] = 'external-link';
          parts[i] =
            '<a href="' +
            parts[i] +
            '" target="_blank" class="external-link">' +
            parts[i] +
            '</a>';
        }

        formattedParts.push(formattedPart);
      }

      let val = '';

      return formattedParts;
    },
  },

  methods: {
    openLink(href) {
      if (window.isElectron) {
        window.electronAPI.openBrowser(href);
      } else {
        window.open(href);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  font-family: $font-base;
  word-wrap: pre-wrap;
}

pre {
  border: none;
  background: none;
  margin: 0px;
  margin-right: 80px;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

pre span {
  margin: 0;
  padding: 0;
}
</style>
