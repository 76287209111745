<template>
  <div
    :id="id"
    class="note-editor"
    :class="{ standalone: standalone, fullwidth: fullwidth }"
  >
    <textarea
      ref="textarea"
      v-model="fullNote"
      @change="onContentChange()"
      @keyup="onContentChange()"
    />
    <div class="note-toolbar">
      <div class="fixed bottom-left">
        <div v-if="saving" class="note-spinner">
          <i class="fa fa-spin fa-spinner" />
        </div>
      </div>
      <div class="editor-menu fixed bottom-right">
        <span style="display: inline-block">
          <div v-if="menuOpen" class="upmenu">
            <button
              id="btn-del-note"
              class="btn-big-circle btn-danger"
              @click="deleteNote()"
            >
              <i class="fa fa-trash" />
            </button>
          </div>
          <button
            class="btn-big-circle btn-secondary"
            @click="menuOpen = !menuOpen"
          >
            <i class="fa fa-cog" />
          </button>
        </span>
        <span style="display: inline-block">
          <button
            id="btn-save"
            :class="{ disabled: !contentDirty }"
            class="btn-big-circle"
            @click="saveNote()"
          >
            <i class="fa fa-save" />
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'note-editor',
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    selectedNote: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      fullNote: '',
      menuOpen: false,
      saving: false,
      contentDirty: false,
      note: null,
      contentCheckInterval: 4000,
      standalone: false,
    };
  },
  mounted: function () {
    if (this.$route.name === 'Note Editor') {
      this.getNote(this.id);
      this.standalone = this.$route.query?.standalone === 'true';
    } else {
      this.note = { ...this.selectedNote };
      this.setupNote();
    }
  },
  methods: {
    setupNote: function () {
      this.fullNote = this.note.title + '\n' + this.note.text;
      this.$refs.textarea.focus();

      setTimeout(() => {
        this.checkContent();
      }, this.contentCheckInterval);
    },
    checkContent: function () {
      if (this.contentDirty) {
        this.saveNote();
      }
      setTimeout(() => {
        this.checkContent();
      }, this.contentCheckInterval);
    },
    onContentChange: function () {
      const parts = this.fullNote.split('\n');
      this.note.title = parts.length > 0 ? parts[0] : 'New Note';
      this.note.text = parts.length > 1 ? parts.slice(1).join('\n') : '';
      this.contentDirty = true;
    },
    getNote: function (id) {
      this.$api
        .get('/notes/' + id)
        .then((res) => {
          this.note = res.data;
          this.setupNote();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    saveNote: function () {
      this.saving = true;
      this.$api
        .put('/notes/' + this.note.id, this.note)
        .then((res) => {
          this.contentDirty = false;
          this.saving = false;
          this.emitter.emit('notes-page:update-notes');
        })
        .catch((err) => {
          console.error(err);
          // this.saving = false;
        });
    },
    deleteNote: function () {
      if (!window.confirm('Are you SURE you want to delete this note?')) {
        return;
      }

      this.$api
        .delete('/notes/' + this.note.id)
        .then((res) => {
          this.emitter.emit('notes-page:update-notes');
          this.$router.push('/notes');
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.note-editor textarea {
  resize: none;
  width: 100%;
  height: 100%;
  border: 0px;
  padding: 8px;
}
.note-editor textarea:first-line,
.note-editor textarea::first-line {
  color: $col-button;
  font-weight: bold;
}

.note-editor {
  top: $topbar-height;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  overflow-y: hidden;
}
.note-editor.standalone {
  top: 0px;
  height: 89vh;
}
.note-editor.fullwidth textarea {
  height: calc(100% - 64px);
}

.note-toolbar {
  height: 64px;
}
.note-spinner {
  font-size: 32px;
  opacity: 0.8;
  color: $col-button;
}
#btn-save {
  margin-left: 20px;
}
#btn-save.disabled {
  opacity: 0.2;
}
.note-editor .upmenu {
  padding-bottom: 8px;
  background: #fff;
  border-radius: 50% 50% 0px 0px;
}

.editor-menu {
  right: 65px;
}

.standalone .editor-menu {
  right: 10px;
}
</style>
