<template>
  <div class="list col-md-4">
    <h2>
      <div class="list-buttons">
        <button v-if="id !== null" @click="onEdit">
          <i class="fa fa-edit" />
        </button>
        <button v-if="id !== null" @click="onDelete">
          <i class="fa fa-trash" />
        </button>
        <button v-if="id !== null && !selectedTaskListId" @click="openTaskList">
          <i class="fa fa-arrow-right" />
        </button>
      </div>
      <span class="header" @click="hidden = !hidden">{{ name }}</span>
      <i
        class="fa header-icon"
        :class="{
          'fa-chevron-down': !hidden,
          'fa-chevron-up': hidden,
        }"
      />
      <div class="task-list-counter">
        <p>
          ({{ openTaskCount }}
          {{
            openTaskCount === 1 ? $t.get('open task') : $t.get('open tasks')
          }})
        </p>
      </div>
    </h2>

    <div :hidden="hidden">
      <draggable
        v-model="modifiedTasks"
        :group="tasksGroup"
        item-key="id"
        @start="onDragStart"
        @end="onDragEnd"
        @add="onAdd"
        @update="onUpdate"
      >
        <template #item="{ element }">
          <task-list-item :task="element" :lists="lists" />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import TaskListItem from '@/components/TaskListItem.vue';
import draggable from 'vuedraggable';

export default {
  components: { TaskListItem, draggable },

  props: {
    tasks: { type: Array, default: () => [] },
    name: { type: String, default: '' },
    id: { type: Number, default: null },
    index: { type: Number, default: null },
    showButtons: { type: Boolean, default: true },
    lists: { type: Array, default: null },
    selectedTaskListId: { type: Number, default: null },
    editTaskList: { type: Function, default: () => {} },
    deleteTaskList: { type: Function, default: () => {} },
  },
  data: function () {
    return {
      hidden: false,
      modifiedTasks: [],
      drag: false,
      tasksGroup: {
        name: this.name,
        put: true,
        pull: true,
      },
    };
  },
  computed: {
    openTaskCount() {
      const openTasks = this.modifiedTasks.filter((task) => !task.completed);
      return openTasks.length;
    },
  },
  watch: {
    tasks(oldVal, newVal) {
      this.modifiedTasks = this.tasks;
    },
  },
  created() {
    this.modifiedTasks = this.tasks;
  },

  mounted: function () {},

  methods: {
    onDragStart(e) {
      this.drag = true;
    },
    onDragEnd(e) {
      this.drag = false;
    },
    onAdd(e) {
      const task_id = e.item.getAttribute('data-task-id');
      this.$api
        .post(`/tasks/${task_id}/list/`, {
          index: e.newIndex,
          list: this.id,
        })
        .then((res) => {
          // this.emitter.emit('tasks-page:update-tasks');
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onUpdate(e) {
      const task_id = e.item.getAttribute('data-task-id');
      this.$api
        .post(`/tasks/${task_id}/list/`, {
          index: e.newIndex,
          list: this.id,
        })
        .then((res) => {
          // this.emitter.emit('tasks-page:update-tasks');
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onEdit() {
      this.editTaskList(this.id);
    },

    onDelete() {
      this.deleteTaskList(this.id);
    },

    openTaskList() {
      this.$router.push(`/tasks/${this.id}`);
    },
  },
};
</script>

<style lang="scss">
.list .task-list-counter {
  font-size: 10px;
}
.list p {
  margin-bottom: -6px;
  color: #817979;
}
.new-task-form {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}
.new-task-form #new-task {
  display: inline-block;
  // width: calc(100% - 64px);
  width: 100%;
}
.new-task-form .small {
  font-size: 10px;
  margin-left: 4px;
}
.list-row {
  margin-left: 0px;
  margin-right: 0px;
}
.list h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px;
  margin-bottom: 6px;
  position: relative;
}
.list h2 .list-buttons {
  position: absolute;
  font-size: 13px;
  padding-right: 3px;
}
.list h2 .list-buttons button {
  margin-right: 3px;
}
.list-row .header {
  cursor: pointer;
  font-size: 1.4rem;
}
.list-row .header-icon {
  opacity: 0.5;
  font-size: 18px;
  margin-left: 5px;
}

.list-row {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 180px;
  overflow-y: auto;
}

@media (min-width: $breakpoint-lg) {
  .list-row {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 160px;
  }
  .list {
    /* min-height: 100%; */
  }
}
</style>
