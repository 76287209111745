<template>
  <li class="note">
    <div class="overlay" />
    <div class="title">
      {{ note.title }}
    </div>
    <div class="text">
      {{ note.text }}
    </div>
  </li>
</template>

<script>
export default {
  props: {
    note: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.note {
  border-bottom: 1px solid #aaa;
  padding: 4px;
  // margin: 4px;
  // border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  height: 70px;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  transition: all 0.5s;
  text-align: left;
}
.note .title {
  font-weight: bold;
  color: $col-button;
  margin-bottom: 8px;
}
.note:hover,
.note:active {
  background: $col-note-hover;
}
.note .text {
  white-space: pre-wrap;
}
.note .overlay {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  // background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  // border-radius: 4px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
</style>
