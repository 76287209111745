<template>
  <NavBar v-if="initialized" :key="user ? user.id : 'none'" :user="user" />
  <router-view v-if="initialized" class="fixed-height" />
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: { NavBar },
  data: function () {
    return {
      initialized: false,
      user: null,
    };
  },
  mounted: function () {
    this.emitter.on('authenticated', (data) => this.onAuthenticated(data));
    this.checkExistingCredentials().then(() => {
      this.$router.appInitialized = true;
      this.$router.replace(this.$router.initialRoute);
      this.initialized = true;
      this.$forceUpdate();
    });
  },
  methods: {
    getCurrentUser: function () {
      return this.user;
    },
    getCurrentView: function () {
      return this.getApp().$refs.routerView
        ? this.getApp().$refs.routerView
        : null;
    },
    checkExistingCredentials: function () {
      return new Promise((resolve) => {
        try {
          const token = JSON.parse(localStorage.token);
          if (token) {
            this.$api
              .validateAuthenticationToken(token)
              .then((res) => {
                this.$api.setAuthenticationToken(token);
                this.setUser(res.data);
                resolve();
              })
              .catch(() => {
                console.warn('Failed to authenticate stored token');
                localStorage.token = null;
                resolve();
              });
          } else {
            resolve();
          }
        } catch {
          resolve();
        }
      });
    },
    onAuthenticated: function (data) {
      localStorage.token = data.token ? JSON.stringify(data.token) : null;
      this.$api.setAuthenticationToken(data.token);
      this.setUser(data.user);
      if (data.token) {
        this.$router.push({ name: 'Tasks' });
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
    setUser: function (user) {
      this.user = user
        ? {
            ...user,
          }
        : null;
      this.emitter.emit('userChanged', this.user);
      this.$router.currentUser = this.user;
    },
    openPopup(path, title) {
      if (window.electronAPI) {
        window.electronAPI.openPopup(path, title);
      } else {
        window.open(path, title, 'width=600,height=600');
      }
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  font-family: $font-base, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: bold;
  font-variant: small-caps;
}

a,
a:focus,
a:visited,
a:active {
  color: $col-button;
  font-weight: bold;
}

.align-center {
  text-align: center;
}
.clear {
  clear: both;
}

.fixed {
  position: fixed;
}

.bottom-right {
  right: 0px;
  bottom: 0px;
  padding: 8px;
}
.bottom-left {
  left: 0px;
  bottom: 0px;
  padding: 8px;
}

.fixed-height {
  height: calc(100% - 110px);
  overflow-y: auto;
}

.btn-big-circle {
  display: flex;
  font-size: 32px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.btn-primary,
.task-body .buttons > button,
.list-buttons button,
.btn-round,
.btn-big-circle {
  background-color: $col-button;
  border: 1px solid $col-button-border;
  color: $col-white !important;
  transition: all 0.5s ease;
}

.btn-primary:hover,
.btn-primary:active,
.task-body .buttons > button:hover,
.task-body .buttons > button:active,
.list-buttons button:hover,
.list-buttons button:active,
.btn-big-circle:hover,
.btn-big-circle:active,
.btn-round:hover,
.btn-round:active {
  background-color: $col-button-hover;
  border: 1px solid $col-button-border-hover;
  transition: all 0.5s ease;
}

.btn-checkbox {
  border: 1px solid $col-button-border;
  color: $col-button;
  opacity: 0.2;
}
.btn-checkbox.active {
  opacity: 1;
}
.btn-checkbox.active {
  background: $col-button;
  color: $col-white;
}
.btn-checkbox:hover,
.btn-checkbox:active,
.btn-checkbox:focus {
  background: $col-button-hover;
  color: $col-white;
}

.task .task-body .buttons > button,
.list-buttons button,
.btn-round {
  border-radius: 50%;
  font-size: 14px;
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;
}

fieldset {
  border: 1px solid $col-border;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;

  legend {
    border-bottom: 1px solid $col-border;
  }
}
</style>
