<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-4 align-center">
        <img src="@/assets/logo_square.png" alt="ChronoTask" class="logo" />
      </div>
    </div>
    <div v-if="isElectron()" class="row justify-content-center">
      <div class="col-sm-4">
        <fieldset>
          <legend>{{ $t.get('Options') }}</legend>
          <div
            class="form-check d-flex justify-content-center align-items-start gap-3 mb-3"
          >
            <input
              id="startMinimized"
              v-model="startMinimized"
              type="checkbox"
              class="form-check-input"
              @change="updateStartMinimized"
            />
            <label class="form-check-label" for="startMinimized">
              {{ $t.get('Start application minimized to system tray') }}
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-4 text-center">
        <div class="small">
          v{{ version.version }}
          <span v-if="version.tag !== null">({{ version.tag }})</span>
        </div>
        <div v-if="version.buildDate !== null" class="small">
          <span translate>{{ $t.get('built') }}: </span>{{ version.buildDate }}
        </div>
        <button
          class="btn btn-danger"
          style="margin-top: 20px"
          @click="logout()"
        >
          <i class="fas fa-sign-out-alt" />
          {{ $t.get('Logout') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { VERSION } from '../environments/version';

export default {
  data: function () {
    return {
      version: VERSION,
      startMinimized: false,
    };
  },
  async mounted() {
    if (
      window.electronAPI &&
      typeof window.electronAPI.setWindowTitle === 'function'
    ) {
      window.electronAPI.setWindowTitle(
        'ChronoTASK: ' + this.$t.get('Account')
      );
    }
    if (
      window.electronAPI &&
      typeof window.electronAPI.getStartMinimized === 'function'
    ) {
      this.startMinimized = await window.electronAPI.getStartMinimized();
    }
  },
  methods: {
    logout: function () {
      this.emitter.emit('authenticated', {
        token: null,
        user: null,
      });
    },
    async updateStartMinimized() {
      if (
        window.electronAPI &&
        typeof window.electronAPI.toggleStartMinimized === 'function'
      ) {
        await window.electronAPI.toggleStartMinimized(this.startMinimized);
      }
    },
    isElectron() {
      return window.isElectron;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  max-width: 40vw;
  max-height: 200px;
}
@media screen and (min-width: $breakpoint-md) {
  .logo {
    max-width: 20vw;
  }
}
</style>
