import * as moment from 'moment';

export default class DateShortcutParser {
  shortcuts = [
    {
      keys: ['today'],
      func: function () {
        return moment().hour(0).minute(0).second(0);
      },
    },
    {
      keys: ['tomorrow'],
      func: function () {
        return moment().hour(0).minute(0).second(0).add(1, 'days');
      },
    },
    {
      keys: ['thismorning', 'tm'],
      func: function () {
        return moment().hour(10).minute(0).second(0);
      },
    },
    {
      keys: ['thisafternoon', 'ta'],
      func: function () {
        return moment().hour(15).minute(0).second(0);
      },
    },
    {
      keys: ['tonight', 'tn'],
      func: function () {
        return moment().hour(21).minute(0).second(0);
      },
    },
    {
      keys: ['tomorrowmorning', 'tmm'],
      func: function () {
        return moment().hour(10).minute(0).second(0).add(1, 'days');
      },
    },
    {
      keys: ['tomorrowafternoon', 'tma'],
      func: function () {
        return moment().hour(15).minute(0).second(0).add(1, 'days');
      },
    },
    {
      keys: ['tomorrownight', 'tmn'],
      func: function () {
        return moment().hour(21).minute(0).second(0).add(1, 'days');
      },
    },
    {
      keys: ['monday', 'mon'],
      func: function (dsp) {
        return dsp.getDayOfWeek(1);
      },
    },
    {
      keys: ['tuesday', 'tue'],
      func: function (dsp) {
        return dsp.getDayOfWeek(2);
      },
    },
    {
      keys: ['wednesday', 'wed'],
      func: function (dsp) {
        return dsp.getDayOfWeek(3);
      },
    },
    {
      keys: ['thursday', 'thu'],
      func: function (dsp) {
        return dsp.getDayOfWeek(4);
      },
    },
    {
      keys: ['friday', 'fri'],
      func: function (dsp) {
        return dsp.getDayOfWeek(5);
      },
    },
    {
      keys: ['saturday', 'sat'],
      func: function (dsp) {
        return dsp.getDayOfWeek(6);
      },
    },
    {
      keys: ['sunday', 'sun'],
      func: function (dsp) {
        return dsp.getDayOfWeek(7);
      },
    },
  ];

  parseShortcut(sc) {
    for (const shortcut of this.shortcuts) {
      if (shortcut.keys.indexOf(sc) > -1) {
        return shortcut.func(this);
      }
    }
    return this.parseExtendedDateShortcut(sc);
  }

  parseExtendedDateShortcut(sc) {
    const ddMMMFormatRegex =
      /^([0-2][1-9]|[1-2]0|3[0-1])(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)([0-2][0-9])?$/i;
    const ddMMFormatRegex =
      /^([0-2][1-9]|[1-2]0|3[0-1])(0[1-9]|1[0-2])([0-2][0-9])?$/;
    const dayIndex = 1;
    const monthIndex = 2;
    const yearIndex = 3;
    let match = null;
    let month = null;
    let format = 'YY-MM-DD';

    if (ddMMMFormatRegex.test(sc)) {
      match = sc.match(ddMMMFormatRegex);
      month = match[monthIndex];
      month = this.parseMonthShortcut(month);
    } else if (ddMMFormatRegex.test(sc)) {
      match = sc.match(ddMMFormatRegex);
      month = match[monthIndex];
    } else {
      return null;
    }

    let year = match[yearIndex];
    const day = match[dayIndex];
    const yearCheck = year !== undefined;
    if (!yearCheck) {
      year = moment().year();
      format = 'YYYY-MM-DD';
    }
    return moment(`${year}-${month}-${day}`, format);
  }

  parseMonthShortcut(month) {
    const monthShortcuts = [
      {
        keys: ['jan'],
        value: '01',
      },
      {
        keys: ['feb'],
        value: '02',
      },
      {
        keys: ['mar'],
        value: '03',
      },
      {
        keys: ['abr'],
        value: '04',
      },
      {
        keys: ['may'],
        value: '05',
      },
      {
        keys: ['jun'],
        value: '06',
      },
      {
        keys: ['jul'],
        value: '07',
      },
      {
        keys: ['aug'],
        value: '08',
      },
      {
        keys: ['sep'],
        value: '09',
      },
      {
        keys: ['oct'],
        value: '10',
      },
      {
        keys: ['nov'],
        value: '11',
      },
      {
        keys: ['dec'],
        value: '12',
      },
    ];
    for (const shortcut of monthShortcuts) {
      if (shortcut.keys.indexOf(month) > -1) {
        return shortcut.value;
      }
    }
    return null;
  }

  getDayOfWeek(dow) {
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dow) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(dow).hour(0).minute(0).second(0);
    }
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, 'weeks').isoWeekday(dow).hour(0).minute(0).second(0);
  }

  parseDateShortcuts(task) {
    const parts = task.text.split(' ');
    const newText = [];
    for (const part of parts) {
      if (part.indexOf('^') === 0) {
        const sc = part.substring(1).toLowerCase();

        const dsp = new DateShortcutParser();
        const due = dsp.parseShortcut(sc);
        console.log('due', due);
        if (due != null) {
          task.due = due;
        }
      } else {
        newText.push(part);
      }
    }
    task.text = newText.join(' ');
  }
}
