import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import TasksPage from '@/views/TasksPage.vue';
import NotesPage from '@/views/NotesPage.vue';
import AccountPage from '@/views/AccountPage.vue';
import NoteEditor from '@/components/NoteEditor.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    redirect: { name: 'Tasks' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/notes',
    name: 'Notes',
    component: NotesPage,
    children: [
      {
        path: ':id',
        name: 'NoteEditor',
        component: NoteEditor,
        props: true,
      },
    ],
  },
  {
    path: '/notes/edit/:id',
    name: 'Note Editor',
    component: NoteEditor,
    props: true,
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: TasksPage,
    children: [
      {
        path: ':id',
        name: 'TaskList',
        component: TasksPage,
      },
    ],
  },
  {
    path: '/timer',
    name: 'Timer',
    component: () => import('@/views/TimerPage.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    props: true,
  },
];

export const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  router.appInitialized = false;
  router.initialRoute = null;

  router.beforeEach((to) => {
    if (!router.initialRoute) {
      router.initialRoute = JSON.parse(JSON.stringify(to));
    }
    if (!router.appInitialized) {
      return false;
    }

    if (
      (!app.config.globalProperties.$api.isAuthenticated() ||
        !router.currentUser) &&
      to.name !== 'Login'
    ) {
      return { name: 'Login' };
    } else {
      const currentView = app.getCurrentView ? app.getCurrentView() : null;
      if (
        currentView &&
        currentView.canLeave !== undefined &&
        !currentView.canLeave()
      ) {
        if (
          !confirm(
            app.config.globalProperties.$t.get(
              'You have unsaved changes!  Are you sure you want to discard them?'
            )
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    }

    return true;
  });

  return router;
};
