<template>
  <div
    class="task"
    :class="{ completed: modifiedTask.completed }"
    :data-task-id="task.id"
  >
    <div v-if="!task.dragging && !task.deleted" class="task-body">
      <div v-if="!modifiedTask.editing" class="buttons side-buttons">
        <button title="Edit" @click="editTask">
          <i class="fa fa-pencil" />
        </button>
        <button
          v-if="modifiedTask.completed == null"
          title="Complete"
          @click="completeTask"
        >
          <i class="fa fa-check" />
        </button>
        <button
          v-if="modifiedTask.completed != null"
          title="Uncomplete"
          @click="completeTask"
        >
          <i class="fa fa-check" />
        </button>
      </div>
      <FormattedTask
        v-if="!modifiedTask.editing"
        class="title"
        :text="modifiedTask.text"
        @click="editTask"
      />
      <div
        v-if="!modifiedTask.editing && modifiedTask.due"
        class="task-due-formatted"
      >
        <label>{{ $t.get('Due') }}</label
        >&nbsp;
        <span>{{ formatDateTime(modifiedTask.due) }}</span>
      </div>

      <div v-if="modifiedTask.editing" class="edit-form">
        <input
          :id="'task_edit_text_' + task.id"
          ref="task_edit_text"
          v-model="modifiedTask.text"
          type="text"
          @change="saveTask"
          @keyup.enter="saveTask"
        />

        <div class="buttons">
          <div
            id="datetimepicker1"
            ref="dateTimePicker"
            class="task-due"
            data-td-target-input="nearest"
            data-td-target-toggle="nearest"
            @click="dateSelector"
          >
            <span
              data-td-target="#datetimepicker1"
              data-td-toggle="datetimepicker"
              >{{ formattedDue }}</span
            >
            <a href="javascript:;" class="btn-danger" @click.stop="clearDate">
              <i class="fa fa-times" />
            </a>
            <input
              :id="'task_edit_due_' + task.id"
              v-model="modifiedTask.due"
              data-td-target="#datetimepicker1"
              type="hidden"
            />
          </div>

          <button
            class="btn-danger btn-left"
            title="Delete"
            @click="deleteTask"
          >
            <i class="fa fa-trash" />
          </button>

          <button class="btn-right" title="Save" @click="saveTask">
            <i class="fa fa-save" />
          </button>

          <div
            class="small"
            style="
              padding: 4px 0px;
              display: flex;
              align-items: center;
              justify-content: right;
              font-size: 12px;
            "
          >
            <select v-model="moveListId">
              <option
                v-for="(list, index) in lists"
                :key="'move_list_' + index"
                :value="list.id"
              >
                {{ list.name }}
              </option>
            </select>
            <button class="btn btn-round small" title="Save" @click="moveTask">
              <i class="fa fa-arrow-right" />
            </button>
          </div>
        </div>
        <div class="clear" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';
import DateShortcutParser from '@/services/DateShortcutParser';
import FormattedTask from './FormattedTask.vue';

export default {
  components: { FormattedTask },
  props: {
    task: {
      type: Object,
      default: () => {
        return {
          text: '',
          due: null,
          dragging: false,
          deleted: false,
          completed: false,
          formattedDateTime: '',
        };
      },
    },
    lists: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    return {
      modifiedTask: {
        editing: false,
        text: '',
        due: null,
      },
      datePicker: null,
      moveListId: -1,
    };
  },

  computed: {
    formattedDue() {
      return this.formatDateTime(this.modifiedTask.due);
    },
  },

  mounted: function () {
    this.modifiedTask = { ...this.modifiedTask, ...this.task };
    this.moveListId = this.task.tasklist?.id;
  },

  methods: {
    editTask() {
      this.modifiedTask.editing = !this.modifiedTask.editing;

      if (this.modifiedTask.editing) {
        this.$nextTick(() => {
          this.$refs.task_edit_text.focus();
          this.datePicker = new TempusDominus(this.$refs.dateTimePicker, {
            display: {
              buttons: {
                close: true,
              },
            },
          });
        });
      }
    },

    completeTask() {
      if (this.modifiedTask.completed !== null) {
        this.modifiedTask.completed = null;
      } else {
        this.modifiedTask.completed = moment().format();
      }

      this.saveTask();
    },

    saveTask() {
      this.modifiedTask.editing = false;

      const dsp = new DateShortcutParser();
      dsp.parseDateShortcuts(this.modifiedTask);

      this.$api
        .put(`/tasks/${this.task.id}`, this.modifiedTask)
        .then((res) => {
          // this.emitter.emit('tasks-page:update-tasks');
        })
        .catch((err) => {
          console.error(err);
        });
    },

    deleteTask() {
      if (!window.confirm('Are you SURE you want to delete this task?')) {
        return;
      }

      this.$api
        .delete(`/tasks/${this.task.id}`)
        .then((res) => {
          this.emitter.emit('tasks-page:update-tasks');
        })
        .catch((err) => {
          console.error(err);
        });
    },

    moveTask() {
      this.$api
        .post(`/tasks/${this.task.id}/list/`, {
          index: 0,
          list: this.moveListId,
        })
        .then((res) => {
          this.emitter.emit('tasks-page:update-tasks');
        })
        .catch((err) => {
          console.error(err);
        });
    },

    dateSelector() {
      this.datePicker.subscribe(Namespace.events.change, (e) => {
        this.modifiedTask.due = e.date;
      });

      this.datePicker.show();
    },

    dateChanged(newDueDate) {
      this.modifiedTask.due = newDueDate;
    },

    clearDate() {
      this.modifiedTask.due = null;
      this.datePicker.clear();
    },

    formatDateTime(dt) {
      if (dt === undefined || dt === null) {
        return 'No due date';
      }

      const m = moment(dt);
      if (m.hour() === 0 && m.minute() === 0) {
        return m.format('LL');
      }

      return m.format('LLL');
    },
  },
};
</script>

<style lang="scss">
.buttons .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.task pre {
  border: none;
  background: none;
  margin: 0px;
  margin-right: 80px;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.task-body {
  border: 1px solid #aaa;
  border-radius: 8px;
  background: #eee;
  position: relative;
  padding: 8px 4px;
  margin-bottom: 5px;
}
.task .task-body .side-buttons {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 8px;
  z-index: 10;
}

.task .edit-form .buttons {
  position: static;
  padding: 4px 8px;
  text-align: center;
}
.task .edit-form .buttons .btn-left {
  float: left;
}
.task .edit-form .buttons .btn-right {
  float: right;
}

.task .task-body .buttons > button,
.list-buttons button,
.btn-round {
  border-radius: 50%;
  font-size: 18px;
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;
}
.task .task-body .buttons > button {
  opacity: 0.1;
}
.task .task-body:hover .buttons > button {
  opacity: 1;
}
.task .task-body .buttons > button i,
.list-buttons button i,
.btn-round i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.task.completed pre {
  text-decoration: line-through;
  opacity: 0.5;
}
.task.completed .task-body {
  background: $col-white;
  border: 1px solid #ccc;
}
.task .edit-form input[type='text'] {
  border: 1px solid #aaa;
  border-radius: 8px;
  background: $col-white;
  padding: 5px;
  margin: 4px;
  font-size: 13px;
  width: calc(100% - 8px);
  // width: calc(100% - 80px);
}
.task .task-due {
  align-items: center;
  background: #fff;
  border-radius: 8px;
  height: 28px;
  margin-bottom: 8px;
}
.task .task-due .btn-danger {
  border-radius: 0px 8px 8px 0px;
  padding: 2px 8px;
  float: right;
}
.task .task-due > span {
  font-size: 12px;
  display: inline-block;
  width: calc(100% - 31px);
  text-align: center;
  line-height: 12px;
  padding: 2px 0px;
}
.task .task-due-formatted {
  font-size: 11px;
}
.task .task-due-formatted label {
  font-weight: bold;
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .task .task-due {
    display: inline-block;
    width: calc(100% - 80px);
    margin-bottom: 0px;
  }
}
@media (min-width: $breakpoint-md) {
}
@media (min-width: $breakpoint-lg) {
  .task .task-due {
    display: inline-block;
    width: calc(100% - 80px);
    margin-bottom: 0px;
  }
}
@media (min-width: $breakpoint-xl) {
}
</style>
