/* global process */
import axios from 'axios';

export default {
  authToken: null,
  apiUrl: null,

  install(app) {
    this.created();
    app.config.globalProperties.$api = this;
  },
  data: {
    client: null,
    authToken: null,
    organizationId: null,
    apiUrl: null,
    initialized: false,
  },
  created: function () {
    this.apiUrl = process.env.VUE_APP_API_URL;
    this.updateClient();
  },
  updateClient: function () {
    const headers = {};
    if (this.authToken) {
      headers['Authorization'] =
        this.authToken.type + ' ' + this.authToken.token;
      headers['Authorization'] = 'Token ' + this.authToken.token;
    }
    this.client = axios.create({
      headers,
    });
  },

  post: function (path, data, config) {
    return this.client.post(
      this.apiUrl + path,
      data === undefined ? {} : data,
      config !== undefined ? config : config
    );
  },
  put: function (path, data) {
    return this.client.put(this.apiUrl + path, data === undefined ? {} : data);
  },
  patch: function (path, data, config) {
    return this.client.patch(
      this.apiUrl + path,
      data === undefined ? {} : data,
      config !== undefined ? config : config
    );
  },
  delete: function (path, data) {
    return this.client.delete(
      this.apiUrl + path,
      data === undefined ? {} : data
    );
  },
  get: function (path, data) {
    return this.client.get(this.apiUrl + path, {
      params: data === undefined ? {} : data,
    });
  },

  // Authentication and stored tokens
  isAuthenticated: function () {
    return this.authToken != null;
  },
  setAuthenticationToken: function (token) {
    this.authToken = token;
    this.updateClient();
  },
  validateAuthenticationToken: function (token) {
    let client = axios.create({
      headers: {
        Authorization: 'Token ' + token.token,
      },
    });

    return client.get(this.apiUrl + '/users/me');
  },
  getCurrentUser() {
    return this.client.get(this.apiUrl + '/users/me');
  },
};
